
.card-tabs .nav-tabs{
    border-radius: 0!important;
    background: #fff!important;
    border-radius: 8px!important;
}
.nav.nav-tabs.tabs-lg{
    padding: 0!important;
    height: 3.5rem;
}
.card-tabs .nav-tabs.tabs-lg .nav-link{
    height: 3.5rem;
    color: #112361;
    background: #fff!important;
    border: none!important;
    font-weight: normal!important;
    font-size: 1.125rem!important;
}
.card-tabs .nav-tabs.tabs-lg .nav-link.active{
    color: #fff!important;
    background-color: #2953E8!important;
    border-radius: 8px!important;
    font-weight: normal!important;
}
.follow-btn{
    border-radius: 8px!important;
    height: 3.5rem;
    padding-left: 2rem!important;
    padding-right: 2rem!important;
    display: flex!important;
    align-items: center!important;
    gap: 1rem;
}
.patient-card{
    border-radius: 1rem;
    background: #fff;
    padding: 2rem 2rem 1.4rem;
    
}
.no-data{
    background-color: #fff;
    border-radius: 1rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:2.6rem
}
.card{
    border: none!important;
    border-radius: 1rem!important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}
.modal-header .modal-title, .modal-body label, .modal-body input{
    color: #112361!important;
}
.modal-body input{
    border-color: #3453DF!important;
    box-shadow: none!important;
}
.modal-content button{
    box-shadow: none;
    background-color: #3453DF;
}
.modal-content{
    border-radius: 24px!important;
    background: #eff0f6!important;
    border: none!important;
    padding: 1rem;
}
.modal-header .close{
    margin: 0!important;
    box-shadow: none;
}
.modal-header .close:hover{
    background: none!important;
}
.modal-header .close span{
    font-size: 2rem;
}
.patient-info{
    padding-left: 2rem;
    padding-right: 2rem!important;
}