.setting-card{
    padding-left: 2rem;
    padding-right: 2rem;
}
.setting-card .card-header{
    height: 6rem
}
.setting-card> .card-body{
    padding-top: 2rem;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
}
input,select{
    border-color:#3453DF!important ;
    box-shadow: none!important;
    color: #112361!important;
}
.save-btn,.save-btn:hover,.save-btn:active{
    box-shadow: none!important;
    margin-top: 2rem;
    margin-bottom: 2.8rem;
}
