li.nav-item{
    padding: 0!important;
}
.search-icon{
    color:"#112361";
    font-size: 1.75rem;
    cursor: pointer;
}
.nav-element{
    width: 5rem!important;
    height: 5rem!important;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
}
.nav-element:hover:after{
    position: absolute;
    height: 0.5rem!important;
    width:5rem !important;
    background: #3453DF;
    content: "";
    bottom: 0.5rem!important;
    bottom: 0rem !important;
    left: 0px!important;
    border-radius:  0  0 1.25rem 1.25rem!important;
    z-index: 99;
}
.search.nav-element:hover svg path{
    fill: #3453DF;
}
.language{
    background-color: #fff!important;
    border: none!important;
    box-shadow: none!important;
}
.notification_dropdown:hover .notification-bell path:not(:first-child) {
    fill: #3453DF;
}
.rounded-div {
    border-radius: 100%; 
    background-color: #3453DF; 
    width: 3.5rem; 
    height: 3.5rem; 
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.5rem;
  }
  .search-box{
    background-color: #EFF0F8;
    border: none;
    border-radius: 8px;
    height: 5rem;
    display: flex;
    align-items: center;
  }
  .search-box:after{
    position: absolute;
    height: 0.5rem!important;
    width:100% !important;
    background: #3453DF;
    content: "";
    bottom: 0.5rem!important;
    bottom: 0rem !important;
    left: 0px!important;
    border-radius:  0  0 1.25rem 1.25rem!important;
    z-index: 99;
  }
  .search-box input{
    background: none;
    border: none;
    box-shadow: none;
    color:#0A113D;
    height: 5rem!important;
  }
  .search-box input.form-control:hover{
    background-color: #EFF0F8!important;
  }
  .search-box input.form-control:focus{
    color: #0A113D!important;
    background-color: #EFF0F8!important;
    border: none!important;
    box-shadow: none!important;
  }
  .search-box .input-group-text{
    padding-right: 1.6rem;
    background: none;
    border: none;
  }
  .header-info span{
    color: #0A113D;
    font-size: 1.25rem;
  }
  
[data-header-position="fixed"] .header {
  border-bottom: 0 !important;
}
.nav-header .brand-logo {
  border-right: 3px solid #eff0f8 !important;
  border-bottom: 0px !important;
  padding-left: 1.688rem !important;
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
  position: relative !important;
  left: 0.188rem !important;
  height: 8rem !important;
}
[data-sidebar-style="mini"] .nav-header .brand-logo {
  padding: 0 !important;
}
@media only screen and (max-width: 767px) {
  .nav-header .brand-logo {
    padding: 0 !important;
  }
}
.header .header-content {
  padding-left: 0 !important;
  padding-right: 2rem !important;
}

.header-left {
  padding-left: 2.5rem !important;
  margin-left: 2.5rem !important;
}
.header-left .dashboard_bar {
  font-family: "Gilroy-SemiBold", sans-serif !important;
  color: #0d1751 !important;
}
.header-right .header-profile > a.nav-link {
  border-left: 0 !important;
}
.navbar-expand .navbar-nav {
  display: flex !important;
  gap: 1.5rem !important;
}
.header-right .header-profile > a.nav-link {
  padding-left: 0 !important;
}
.btn {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.navbar-expand .navbar-nav .nav-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.header-right .header-profile > a.nav-link .header-info span {
  font-family: "Gilroy-Regular" sans-serif !important;
  font-size: 1.25rem !important;
}
.flag .btn:not(:disabled):not(.disabled) {
  display: block !important;
  color: #0a113d !important;
}
a:hover{
  text-decoration: none!important;
}
