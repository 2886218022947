.card-header {
    background: #fff !important;
    border-radius: 1rem 1rem 0 0 !important;
}

.card-header h4 {
    color: #3453DF !important;
}

.form-control {
    height: 3.5rem !important;
    border-radius: 8px !important;
}

#appointments {
    height: calc(100vh - (14rem + 40px)) !important;
}

.react-calendar {
    width: 100% !important;
    border: none !important;
    border-bottom: 1px solid rgba(17, 35, 97, 0.24) !important;
}

.react-calendar__tile.react-calendar__month-view__days__day {
    box-shadow: none !important;
    border: none !important;
    flex-direction: column; 
    display: flex !important; 
    align-items: center !important;
    justify-content: center !important;
    gap: 0.25em;
    color: #112361;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
    color: #112361 !important;
    opacity: 0.24 !important;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
    color: #112361;
}

.react-calendar__month-view__weekdays__weekday {
    color: #3453DF;
    padding: 0.2rem!important;
}

abbr[title] {
    text-decoration: none !important;
    cursor: default !important;
    font-size: 1rem!important;
}

.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active,
.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
    background-color: #3453DF !important;
    color: #fff !important;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
.react-calendar__navigation__label,
.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    border: none;
    box-shadow: none;
}

.react-calendar__navigation__label {
    order: 1;
    text-align: start;
    font-size: 1.25rem;
    text-transform: capitalize;
    pointer-events: none;
}

.react-calendar__navigation__arrow {
    order: 2;
    color: #112361;
    opacity: 0.24;
    font-size: 1.5rem!important;
}

.react-calendar__tile--now {
    background-color: rgb(52, 83, 223, 0.1) !important;
}
.widget-timeline .timeline.timeline-appointments:before{
    background: none!important;
    border-left: 1px dashed rgb(17, 35, 97,0.24);
}
.timeline.timeline-appointments .timeline-badge{
    border: none!important;
    background: none!important;
}
.timeline.timeline-appointments .timeline-badge::after{
    width: 10px!important;
    border: none!important;
    color: #fff!important;
    background:#2BC155!important ;
}
.timeline-appointments.timeline > li > .timeline-panel:after{
    border-width: 0!important;
}
.timeline-appointments.timeline > li > .timeline-panel{
    border: none!important;
    margin: 0!important;
    padding-top: 10px!important;
    color: #112361!important;
}