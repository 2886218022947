
.main{
    height: 100vh;
    padding: 2rem 3rem !important;
    flex-wrap: nowrap!important;
    gap: 3rem;
    background: #fff;
}
.img{ 
    height: 100%;
    width: 100%;
    background-color: rgba(17, 35, 97, 0.08);
    border-radius: 24px;
}

.login{
    height: 100%;
    width: 100%;
    display: flex!important;
    justify-content: center;
    align-items: center;
}
.login-box{
    width: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}
.login-footer{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    color: #112361;
    opacity: 0.32;
}
p,label{
    font-size: 1.25rem;
}
label{
   color: rgba(17, 35, 97,0.24);
   margin-bottom: 1rem!important;
}
input,button{
    border-color: rgb(52, 83, 223)!important;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25);
}
.btn-primary:active{
    background-color: #112361!important;
    color: #fff!important;
    outline: none!important;
    box-shadow: none!important;
}
.btn-primary{
    background-color:rgb(52, 83, 223) !important;
}
.btn-primary:hover{
    background-color: #fff!important;
    color: rgb(52, 83, 223)!important;
    border: 1px solid rgb(52, 83, 223)!important;
}
.fpwd{
    color: rgb(52, 83, 223)!important;
    text-decoration: none!important;
}
.login-box h1{
    margin-bottom: 0.75rem!important;
}
.login-box p{
    margin-bottom: 2rem!important;
}


