.patient.page-titles{
    background: none;
}
.patient .breadcrumb .breadcrumb-item a{
    color: #112361;
}
.patient .breadcrumb .breadcrumb-item.active a{
    color: #112361;
    opacity: 0.64;
}
.patient .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
 content:">"!important;
 color: #112361;
}
.btn, .btn:hover,.btn:active{
    border: none!important;
    box-shadow: none!important;
}
.notify-btn,.send-btn{
    background: #3453DF!important;
    color: #fff!important;
}
.notify-btn:hover,.send-btn:hover{
    border: 2px solid #3453DF!important;
    color: #3453DF!important;
}
.unfollow-btn{
    background: #ED1607!important;
    color: #fff!important;
}
.unfollow-btn:hover{
    border: 2px solid #ED1607!important;
    color: #ED1607!important;
}
.label{
    color: #0A113D!important;
    opacity: 0.48!important;
}
.diseases{
    color: #fff!important;
    opacity: 0.64!important;
}
.patient-tab.nav-pills .nav-item .nav-link{
    height: 3.5rem!important;
    color: #112361!important;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
}
.patient-tab.nav-pills .nav-item .nav-link.active{
    background: #3453DF!important;
    color: #fff!important;
}
.stat-row{
    background-color: rgba(52,83,223,0.04)!important;
    border-radius: 1rem 1rem .5rem .5rem!important;
}
.btn-stat{
    border: none!important;
    box-shadow: none!important;
    color: #112361!important;
}
.btn-stat:hover{
    border: 2px solid #3453DF!important;
    color: #3453DF!important;
    background: transparent!important;
}
.btn-stat:focus{
    border: none!important;
    box-shadow: none!important;
}
.btn-graph{
    border-radius: 1rem .5rem .5rem .5rem!important;
}
.btn-pie{
    border-radius: .5rem 1rem .5rem .5rem!important;
}
.btn-selected{
    border: 2px solid #3453DF!important;
color: #fff!important;
background-color: #3453DF!important;
}
.notification-modal > label.modal-label {
    opacity:1!important
}
.text-wrapped{
    white-space: pre-wrap!important; /* Allow text to wrap onto multiple lines */
  overflow-wrap: break-word!important; 
}