.rbc-event{
    color: #112361;
    border: none!important;
    border-radius: 1rem;
    /* padding-top: .5rem!important; */
    /* min-height: fit-content!important; */
    height: auto!important;
    width: 100%!important;
    cursor: default;
    justify-self: center;
    left: 0%!important;
}
.rbc-event:focus{
    outline: none!important;
}
.rbc-event-label{
    display: none!important;
    font-family: "";
}
.rbc-event-content .event{
    text-transform: capitalize;
}
.event-time{
    color: rgba(17,35,97,0.48);
}
.patient-city{
    font-size: 11px;
}
.rbc-button-link{
    box-shadow: none!important;
    cursor: default;
}
.rbc-row-segment{
    /* height: fit-content!important; */
}
.rbc-month-row {
    overflow: visible;
}
.rbc-time-content{
    overflow-y: auto!important;
    max-height: 480px!important;
}
.rbc-day-slot .rbc-time-slot{
    border-top: none!important;
}
.rbc-time-slot{
    min-height: 70px!important;
}
.rbc-timeslot-group{
    border-bottom: none!important;
}
.rbc-day-slot .rbc-events-container{
    margin-right: 0;
}
.rbc-allday-cell{
    display: none!important;
}
.rbc-btn-group{
 /* background: #fff; */
 border-radius: 0.5rem!important;
}

.rbc-btn-group button{
    /* border: none; */
    box-shadow: none!important;
    border: 1px solid #112361!important;
    margin-right: 5px;
}

.rbc-btn-group .rbc-active{
    background-color: #3453DF!important;
    color: #fff!important;
}
.rbc-toolbar{
    background: rgba(52,83,223,0.04)!important;
    padding:2rem 1rem!important;
    margin-bottom: 0!important;
}
.rbc-toolbar button:focus{
    background: transparent!important;
}
.big-calendar-card{
    padding: 0!important;
    background: rgba(52,83,223,0.04)!important;
}
.rbc-header {
    padding: 3px;
}
.rbc-time-gutter.rbc-time-column{
    background: rgba(52,83,223,0.04)!important;

}
.rbc-event:has(> .rbc-event-content .expanded){
    z-index: 10!important;
}
.rbc-event:has(> .rbc-event-content .consultation){
    background: #fee9c1!important;
}
.rbc-event:has(> .rbc-event-content .accepted){
    background: #c5ead4!important;
}
.rbc-event:has(> .rbc-event-content .accepted.expanded){
    background: #c5ead4!important;
}
.arrow-up{
    rotate: 180deg;
}