.btn-save{
    background: #3453DF!important;
    color: #fff !important;
}
.btn-save:hover{
    background: #fff;
    border: 1px solid #3453DF!important;
    color:#3453DF;
}
modal > input,modal > textarea{
    border: 1px solid #3453DF!important
}
.status-badge{
    height:3.5rem!important;
    display:flex!important;
    align-items: center!important;
    justify-content: center!important;
    background: black!important;
    color: #fff;
    border-radius: 1rem!important;
}